import { useState, useEffect, useContext } from "react";
import ExploreCard from "./ExploreCard";
import Web3Context from "../../contexts/Web3Context";
import { useParams } from "react-router-dom";
import ALL_PROJECT_DETAILS from "../../allProjects.json";
import "./style.css";
import NETWORK_DETAILS from "../../contexts/networkDetails.json";
import Loader from "../../components/Loader";

export default function Explore() {
  const [stakedcount, setStakedCount] = useState(false);
  const [unMigratedCount, setUnMigratedCount] = useState(false);
  const { id } = useParams();

  const {
    account,
    getUserStaked,
    getUserUnStaked,
    claimRewards,
    claimReflections,
    unstake,
    migrateTokens,
    contractObjects,
    calculateReward,
    selectedProject,
    setSelectedProject,
    update: globalUpdate,
    getContractBalance,
    unstakeEmergency,
    currentNetwork,
    setCurrentNetwork,
    getUnMigratedTokens,
    getChain2Nfts,
    getChain2Tokens,
    presentNetwork,
    promptChain,
    retrieveAll,
  } = useContext(Web3Context);
  const projectDetails = ALL_PROJECT_DETAILS[selectedProject];
  const [stakeddata, setstakeddata] = useState(false);
  const [update, setUpdate] = useState(0);
  const [unMigrated, setUnMigrated] = useState(false);
  const [contractBalance, setContractBalance] = useState(undefined);
  const [signatures, setSignatures] = useState();
  const [loading, setLoading] = useState(false);
  async function getdata() {
    const [t, k] = await Promise.all([getUserStaked(), getUnMigratedTokens()]);
    setstakeddata(t);
    setStakedCount(t?.length);
    setUnMigrated(k);
    setUnMigratedCount(k?.length);
  }
  async function claimRewardsclicked(e) {
    e.preventDefault();
    await claimRewards(stakeddata);
    setUpdate((val) => val + 1);
  }
  async function unstakeclicked(e) {
    e.preventDefault();
    await retrieveAll(signatures);

    setUpdate((val) => val + 1);
  }
  async function stakeclicked(e) {
    e.preventDefault();
    await migrateTokens(unMigrated);
    setUpdate((val) => val + 1);
  }
  async function claimReflectionsclicked(e) {
    e.preventDefault();
    const tokens = await calculateReward(stakeddata);
    await claimReflections(tokens[1]);
    setUpdate((val) => val + 1);
  }
  const [isChain1, setIsChain1] = useState(true);
  const {
    CHAIN_1: { CHAIN_ID: CHAIN_1_ID },
    CHAIN_2: { CHAIN_ID: CHAIN_2_ID },
  } = ALL_PROJECT_DETAILS[selectedProject].contractAddress;
  // const presentNetwork = window?.ethereum?.networkVersion?.toString();

  useEffect(() => {
    const fToRun = async () => {
      if (id) {
        setSelectedProject(id);
        getContractBalance()?.then((res) => {
          setContractBalance(parseFloat(parseFloat(res).toFixed(4)));
        });
      }
      if (account && id && contractObjects) {
        console.log("Updateing list");
        setLoading(true);
        if (isChain1 && presentNetwork == CHAIN_1_ID) {
          const res = await getUnMigratedTokens();
          setUnMigrated(res);
          setUnMigratedCount(res?.length);
        } else if (!isChain1 && presentNetwork == CHAIN_2_ID) {
          const [userTokens, _signatures] = await getChain2Nfts();
          console.log(signatures);
          setstakeddata(userTokens);
          setSignatures(_signatures);
          setStakedCount(_signatures?.length);
        }
        setLoading(false);
      }
    };
    if (account && id) {
      fToRun();
    }
  }, [update, account, id, contractObjects, globalUpdate, presentNetwork]);
  // return <Loader />;
  // const [isIncorrectNetwork, setIsIncorrectNetwork] = useState(true);
  // useEffect(() => {
  //   const presentNetwork = window?.ethereum?.networkVersion?.toString();
  //   console.log(presentNetwork);

  //   const _isIncorrectNetwork =
  //     (isChain1 && presentNetwork != CHAIN_1_ID) ||
  //     (!isChain1 && presentNetwork != CHAIN_2_ID);
  //   setIsIncorrectNetwork(_isIncorrectNetwork);
  // }, [update, isChain1]);
  // console.log(presentNetwork);
  const isIncorrectNetwork =
    (isChain1 && presentNetwork != CHAIN_1_ID) ||
    (!isChain1 && presentNetwork != CHAIN_2_ID);

  return (
    <>
      <section className="explore-area pt-0">
        <div className="container">
          <div className="row justify-content-center text-center">
            <Checkbox
              isChain1={isChain1}
              setCurrentNetwork={setCurrentNetwork}
              CHAIN_1_ID={CHAIN_1_ID}
              CHAIN_2_ID={CHAIN_2_ID}
              setIsChain1={setIsChain1}
            />
          </div>
          {loading ? (
            <Loader />
          ) : (
            <>
              {isIncorrectNetwork ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      padding: "160px",
                    }}
                    className="m-auto w-auto"
                  >
                    <div> You're on Wrong Chain</div>
                    <button
                      className="btn btn-bordered-white btn-smaller mt-3"
                      onClick={() => promptChain()}
                    >
                      Switch Network
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}
              {!isIncorrectNetwork && (
                <>
                  <div className=" mt-4 row justify-content-center text-center">
                    <div className="col-12">
                      <div className="explore-menu btn-group flex-wrap justify-content-center text-center mb-4">
                        {isChain1 ? (
                          unMigratedCount ? (
                            <>
                              You have {unMigratedCount}{" "}
                              {unMigratedCount == 1 ? "NFT" : "NFTS"} to Migrate
                            </>
                          ) : (
                            "You don't have any NFTS to Migrate"
                          )
                        ) : stakedcount ? (
                          <>
                            You have {stakedcount}{" "}
                            {stakedcount == 1 ? "NFT" : "NFTS"} to Retrieve
                          </>
                        ) : (
                          "You don't have any NFTS to Migrate!"
                        )}
                      </div>
                    </div>
                  </div>
                  {isChain1 ? (
                    <div className="row justify-content-center text-center">
                      <div className="col-12">
                        {!projectDetails?.disableStaking &&
                          unMigrated &&
                          unMigrated.length != 0 && (
                            <div className="explore-menu btn-group flex-wrap justify-content-center text-center mb-4">
                              <button
                                className="btn btn-bordered-white btn-smaller mt-3"
                                onClick={stakeclicked}
                              >
                                Migrate All
                              </button>
                            </div>
                          )}
                      </div>
                    </div>
                  ) : (
                    <div className="row justify-content-center text-center">
                      <div className="col-12">
                        {stakedcount > 0 && (
                          <div className="explore-menu btn-group flex-wrap justify-content-center text-center mb-4">
                            <button
                              className="btn btn-bordered-white btn-smaller mt-3"
                              onClick={unstakeclicked}
                            >
                              Retrieve All
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div style={{ textAlign: "center" }}>
                    Your NFTs
                    <br />
                  </div>
                  <div
                    style={{ textAlign: "center" }}
                    className="row items justify-content-center explore-items"
                  >
                    {!isChain1 && stakeddata && (
                      <>
                        {stakeddata.length ? (
                          stakeddata.map((item) => (
                            <ExploreCard
                              key={item}
                              token={item}
                              staked={!isChain1}
                            />
                          ))
                        ) : (
                          <> You don't have any NFTs</>
                        )}
                      </>
                    )}
                    {isChain1 &&
                      unMigrated &&
                      (unMigrated.length ? (
                        unMigrated.map((item) => (
                          <ExploreCard
                            key={item}
                            staked={!isChain1}
                            token={item}
                          />
                        ))
                      ) : (
                        <></>
                      ))}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </section>
    </>
  );
}
function Checkbox({
  isChain1,
  setCurrentNetwork,
  CHAIN_1_ID,
  CHAIN_2_ID,
  setIsChain1,
}) {
  return (
    <div className="checkbox-container">
      <div className={` checkbox-label ${isChain1 ? "selected" : ""}`}>
        {NETWORK_DETAILS[CHAIN_1_ID?.toString()].name}
      </div>
      <label class="switch">
        <input
          value={false}
          onChange={(e) => {
            let _isChain1 = !e?.target?.checked;
            setIsChain1(_isChain1);
            if (_isChain1) {
              setCurrentNetwork(NETWORK_DETAILS[CHAIN_1_ID?.toString()]);
            } else {
              setCurrentNetwork(NETWORK_DETAILS[CHAIN_2_ID?.toString()]);
            }
          }}
          type="checkbox"
        />
        <span class="slider round"></span>
      </label>
      <div className={` checkbox-label ${!isChain1 ? "selected" : ""}`}>
        {NETWORK_DETAILS[CHAIN_2_ID?.toString()].name}{" "}
      </div>
    </div>
  );
}
