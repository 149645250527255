import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import ALL_PROJECTS from "../allProjects.json";
import TOKEN_PROJECTS from "../tokenMigrateProject.json";
const initData = {
  pre_heading: "Auctions",
  heading: "Live Auctions",
  content:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.",
  btnText: "Load More",
};

const data = Object.keys(ALL_PROJECTS)
  ?.map((key) => ALL_PROJECTS[key])
  .filter((project) => !project?.hideExplore);
const tokenData = Object.keys(TOKEN_PROJECTS)
  ?.map((key) => ALL_PROJECTS[key])
  .filter((project) => !project?.hideExplore)
  .slice(0, 1);

class AuctionsTwo extends Component {
  state = {
    initData: {},
    data: [],
  };
  componentDidMount() {
    this.setState({
      initData: initData,
      data: data,
    });
  }
  render() {
    return (
      <section className="live-auctions-area load-more">
        <div className="container">
          <h1
            className="mt-4 mx-auto pt-4 flex text-center"
            style={{ display: "flex", color: "#fff", justifyContent: "center" }}
          >
            <div
              className="inline"
              style={{ color: "#ff66c4", marginRight: "16px" }}
            >
              All{" "}
            </div>
            Projects
          </h1>
          <Tabs className="Tabs">
            <TabList>
              <Tab>ERC 721 NFTs</Tab>
              <Tab>ERC20 Tokens</Tab>
            </TabList>
            <TabPanel>
              <div>ERC 721 NFTs</div>
              <div className="row justify-content-center items">
                {this.state.data.map((item, idx) => {
                  return (
                    <div
                      key={`auct_${idx}`}
                      className={`p-8 mt-2 ${
                        item?.banner ? "col-12 " : `col-12 col-lg-6`
                      }`}
                    >
                      <div className="card">
                        <div className="image-over">
                          <Link to={`/nfts/${item?.id}`}>
                            <img
                              style={{ height: "325px", objectFit: "cover" }}
                              className="card-img-top"
                              src={item.image}
                              alt=""
                            />
                          </Link>
                        </div>
                        {/* Card Caption */}
                        <div className="card-caption col-12 p-0">
                          {/* Card Body */}
                          <div className="card-body ">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyItems: "center",
                                alignItems: "center",
                              }}
                            >
                              <Link to={`/nfts/${item?.id}`}>
                                <h5
                                  className="mb-0"
                                  style={{ fontSize: "28px" }}
                                >
                                  {item?.title}
                                </h5>
                              </Link>
                              <a href={`https://${item?.website}`}>
                                {item?.website}
                              </a>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyItems: "center",
                                alignItems: "center",
                              }}
                            >
                              <Link
                                to={`/nfts/${item?.id}`}
                                className="btn btn-bordered-white btn-smaller mt-3"
                              >
                                View NFTS
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </TabPanel>
            <TabPanel>
              <div>ERC20 Tokens</div>
              <div className="row justify-content-center items">
                {tokenData.map((item, idx) => {
                  return (
                    <div
                      key={`auct_${idx}`}
                      className={`p-8 mt-2 ${
                        item?.banner ? "col-12 " : `col-12 col-lg-6`
                      }`}
                    >
                      <div className="card">
                        <div className="image-over">
                          <Link to={`/tokens/${item?.id}`}>
                            <img
                              style={{ height: "325px", objectFit: "cover" }}
                              className="card-img-top"
                              src={item.image}
                              alt=""
                            />
                          </Link>
                        </div>
                        {/* Card Caption */}
                        <div className="card-caption col-12 p-0">
                          {/* Card Body */}
                          <div className="card-body ">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyItems: "center",
                                alignItems: "center",
                              }}
                            >
                              <Link to={`/tokens/${item?.id}`}>
                                <h5
                                  className="mb-0"
                                  style={{ fontSize: "28px" }}
                                >
                                  {item?.title}
                                </h5>
                              </Link>
                              <a href={`https://${item?.website}`}>
                                {item?.website}
                              </a>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyItems: "center",
                                alignItems: "center",
                              }}
                            >
                              <Link
                                to={`/tokens/${item?.id}`}
                                className="btn btn-bordered-white btn-smaller mt-3"
                              >
                                View Tokens
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </section>
    );
  }
}

export default AuctionsTwo;
