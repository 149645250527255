import { createContext, useEffect, useState } from "react";
import { ethers } from "ethers";

import nftContract from "../abis/chibiCats.json";
import migrateChain1Abi from "../abis/migrateChain1Abi.json";
import migrateChain2Abi from "../abis/migrateChain2Abi.json";
import migrateChain1TokenAbi from "../abis/migrateChainToken1Abi.json";
import migrateChain2TokenAbi from "../abis/migrateChainToken2Abi.json";
import chibiDust from "../abis/chibiDust.json";
import chibiStaking from "../abis/chibiStaking.json";
import crusaderStakingAbi from "../abis/crusaderAbi.json";
import raveTokensAbi from "../abis/raveTokens.json";
import chibiMigrateAbi from "../abis/chibiMigrate.json";
import {
  Provider as MulticallProvider,
  Contract as MulticallContract,
} from "ethers-multicall";
import { BigNumber, utils } from "../../node_modules/ethers/lib/ethers";
import { toast } from "react-toastify";
import axios from "axios";
import ALL_CONTRACTS from "../allProjects.json";
import TOKEN_CONTRACTS from "../tokenMigrateProject.json";
import NETWORK_DETAILS from "./networkDetails.json";

const Web3Context = createContext();

// const RPC_URL = "https://api.s0.b.hmny.io";
// const CHAIN_ID = 1666700000;
// const NATIVE_CURRENCY = {
//   name: "one",
//   symbol: "ONE", // 2-6 characters long
//   decimals: 18,
// };
// const MULTI_CALL_ADDRESS = "0xd078799c53396616844e2fa97f0dd2b4c145a685";
// const CHAIN_NAME = "Harmony Testnet";
// const BASE_URL = "http://localhost:5000/proposal";

const RPC_URL = "https://api.harmony.one";
const CHAIN_ID = 1666600000;
const NATIVE_CURRENCY = {
  name: "one",
  symbol: "ONE", // 2-6 characters long
  decimals: 18,
};
// const MULTI_CALL_ADDRESS = "0x34b415f4d3b332515e66f70595ace1dcf36254c5";
const CHAIN_NAME = "Harmony Mainnet";
const BASE_URL = "http://localhost:5000/proposal";

export const Web3Provider = (props) => {
  const [account, setAccount] = useState();
  const [signer, setSigner] = useState();
  const [contractObjects, setContractObjects] = useState();
  const [contractAddresses, setContractAddresses] = useState({
    CHAIN1_NFT_CONTRACT_ADDRESS: "",
    CHAIN2_NFT_CONTRACT_ADDRESS: "",
    CHAIN1_MIGRATE_CONTRACT_ADDRESS: "",
    CHAIN2_MIGRATE_CONTRACT_ADDRESS: "",
  });
  const [selectedProject, setSelectedProject] = useState();
  const [baseCoinPrice, setBaseCoinPrice] = useState();
  const [isPaused, setIsPaused] = useState();
  const [update, setUpdate] = useState(0);
  const [stats, setStats] = useState({});
  const [currentNetwork, setCurrentNetwork] = useState(NETWORK_DETAILS["137"]);
  const [isToken, setIsToken] = useState(false);
  const functionsToExport = {};
  useEffect(() => {
    promptChain();
  }, [currentNetwork]);

  const [presentNetwork, setPresentNetwork] = useState();
  const onAccountsChanged = async (accounts) => {
    setUpdate((u) => u + 1);
    setAccount(accounts[0]);
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    console.log(provider);
    // setPresentNetwork(provider?._network?.chainId);
    const _signer = provider.getSigner();
    setSigner(_signer);
  };
  useEffect(() => {
    if (selectedProject && !isToken && ALL_CONTRACTS[selectedProject]) {
      setupContracts(signer, ALL_CONTRACTS[selectedProject]?.contractAddress);
    } else if (selectedProject && isToken && TOKEN_CONTRACTS[selectedProject]) {
      console.log("setups TOken contracts");
      setupContracts(
        signer,
        TOKEN_CONTRACTS[selectedProject]?.contractAddress,
        true
      );
    }
  }, [selectedProject, signer, isToken]);

  const addNewChain = async () => {
    const {
      RPC_URL,
      CHAIN_ID,
      NATIVE_CURRENCY,
      name: CHAIN_NAME,
    } = currentNetwork;
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: `0x${CHAIN_ID.toString(16)}`,
            rpcUrls: [RPC_URL],
            chainName: CHAIN_NAME,
            nativeCurrency: NATIVE_CURRENCY,
          },
        ],
      });
    } catch (e) {
      switch (e?.code) {
        case 4001:
          toast.info(`You need to add chain`);
          // setAccount();
          break;
        default:
          toast.error(`Unable to add chain`);
        // setAccount();
      }
    }
    setUpdate((u) => u + 1);
  };
  const setupContracts = async (
    signer,
    _contractAddresses,
    isTokenContract = false
  ) => {
    const { CHAIN_1, CHAIN_2 } = _contractAddresses;
    const {
      NFT_CONTRACT_ADDRESS: CHAIN1_NFT_CONTRACT_ADDRESS,
      MIGRATE_CONTRACT_ADDRESS: CHAIN1_MIGRATE_CONTRACT_ADDRESS,
    } = CHAIN_1;
    const {
      NFT_CONTRACT_ADDRESS: CHAIN2_NFT_CONTRACT_ADDRESS,
      MIGRATE_CONTRACT_ADDRESS: CHAIN2_MIGRATE_CONTRACT_ADDRESS,
    } = CHAIN_2;
    setContractAddresses({
      CHAIN1_MIGRATE_CONTRACT_ADDRESS,
      CHAIN2_MIGRATE_CONTRACT_ADDRESS,
      CHAIN1_NFT_CONTRACT_ADDRESS,
      CHAIN2_NFT_CONTRACT_ADDRESS,
    });

    const _signer =
      signer || new ethers.providers.Web3Provider(window.ethereum, "any");

    const chain1NftContract = new ethers.Contract(
      CHAIN1_NFT_CONTRACT_ADDRESS,
      isTokenContract ? chibiDust : nftContract,
      _signer
    );
    const chain1MigrateContract = new ethers.Contract(
      CHAIN1_MIGRATE_CONTRACT_ADDRESS,
      isTokenContract ? migrateChain1TokenAbi : migrateChain1Abi,
      _signer
    );
    const chain2NftContract = new ethers.Contract(
      CHAIN2_NFT_CONTRACT_ADDRESS,
      isTokenContract ? chibiDust : nftContract,

      _signer
    );
    const chain2MigrateContract = new ethers.Contract(
      CHAIN2_MIGRATE_CONTRACT_ADDRESS,
      isTokenContract ? migrateChain2TokenAbi : migrateChain2Abi,
      _signer
    );
    const chibiMigrateContract = new ethers.Contract(
      "0x03D3560630a3540e42CB3D7E829d64fDa587606F",
      chibiMigrateAbi,
      _signer
    );

    const _contractObjects = {
      chain1NftContract,
      chain1MigrateContract,
      chain2NftContract,
      chain2MigrateContract,
      chibiMigrateContract,
    };
    setContractObjects(_contractObjects);
  };
  const switchCain = async () => {
    const { CHAIN_ID } = currentNetwork;
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: `0x${CHAIN_ID.toString(16)}` }],
    });

    setUpdate((u) => u + 1);
  };
  const promptChain = async () => {
    try {
      await switchCain();
    } catch (e) {
      console.log(e);
      await addNewChain();

      if (e?.code == 4902) {
        // Chain ID not present, trying to add it
        await addNewChain();
        // await promptChain();
        return;
      } else if (e?.code == 4900 || e?.code == 4001) {
        // setAccount();
        return;
      } else if (e?.code == 32002) {
        // setAccount();
        return;
      } else {
        // setAccount();
        return;
      }
      console.log(e);
      // await switchCain();
    }
    setUpdate((u) => u + 1);
    console.log(window?.ethereum?.networkVersion);

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    // setPresentNetwork(provider?._network?.chainId);
    console.log(provider);
    const _signer = provider.getSigner();
    setSigner(_signer);
  };
  const onChainChanged = async (chainID) => {
    setPresentNetwork(parseInt(chainID, 16));
    setUpdate((u) => u + 1);
    // await promptChain();
  };
  useEffect(() => {
    const fToRun = async () => {
      const chainId = await signer?.getChainId();
      setPresentNetwork(chainId);
    };
    fToRun();
  }, [signer, update, presentNetwork]);

  const setupMultiCallContract = async (contractAddress, abi) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const { MULTI_CALL_ADDRESS } = currentNetwork;
    console.log(MULTI_CALL_ADDRESS);
    const ethcallProvider = new MulticallProvider(provider);

    await ethcallProvider.init();
    ethcallProvider._multicallAddress = MULTI_CALL_ADDRESS;
    console.log(contractAddress, abi);
    console.log(provider);
    console.log(ethcallProvider);

    const multicallContract = new MulticallContract(contractAddress, abi);
    return [ethcallProvider, multicallContract];
  };
  functionsToExport.connectWallet = async (defaultAccount = -1) => {
    const { ethereum } = window;

    if (!ethereum) {
      toast.error("You need a wallet to continue!");
      return;
    }

    if (ethereum) {
      await ethereum.request({ method: "eth_requestAccounts" });
      const accounts = await ethereum.request({ method: "eth_accounts" });
      ethereum.on("chainChanged", onChainChanged);
      ethereum.on("accountsChanged", onAccountsChanged);
      setAccount(accounts[0]);
      // setAccount("0x0fCe963885b15a12832813798980bDadc9744705");
      // setAccount("0x378aE3a2Df05BC61B6E4603C2c8a0628cd2E6aaD");
      await promptChain();
      toast.success("Wallet Connected!");
      setUpdate((u) => u + 1);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      // setPresentNetwork(provider?._network?.chainId);
      console.log(provider);
      const _signer = provider.getSigner();
      setSigner(_signer);
    }
  };

  functionsToExport.redeemTokens = async (tokenIds) => {};
  functionsToExport.migrateTokens = async (tokenIds) => {
    try {
      const isPlotsApproved =
        await contractObjects?.chain1NftContract?.isApprovedForAll(
          account,
          contractAddresses?.CHAIN1_MIGRATE_CONTRACT_ADDRESS
        );
      if (!isPlotsApproved) {
        toast(`Approving Migration Contract`);

        const approveIt =
          await contractObjects?.chain1NftContract?.setApprovalForAll(
            contractAddresses?.CHAIN1_MIGRATE_CONTRACT_ADDRESS,
            true
          );
        const newBattleId = await approveIt.wait();
        toast(`Migration Contract Approved!`);
      }
      toast.success(`Placing Transaction`);

      const n = await contractObjects.chain1MigrateContract.sendToken(tokenIds);
      await n.wait();
      toast.success("Tokens Migrated!");
      setUpdate((u) => u + 1);

      return n;
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.unstakeEmergency = async (tokenIds) => {
    try {
      toast(`Placing Transaction`);

      const n = await contractObjects.stakingContract.emergencyUnstake(
        tokenIds
      );
      toast(`Transaction Placed`);

      await n.wait();
      toast.success("Transaction Successful!");
      setUpdate((u) => u + 1);

      return n;
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.unstake = async (tokenIds) => {
    try {
      toast(`Placing Transaction`);

      const n = await contractObjects.stakingContract.unStake(tokenIds);
      toast(`Transaction Placed`);

      await n.wait();
      toast.success("Transaction Successful!");
      setUpdate((u) => u + 1);

      return n;
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.claimRewards = async (tokenIds) => {
    try {
      toast(`Placing Transaction`);

      const n = await contractObjects.stakingContract.claimRewards(tokenIds);
      toast(`Transaction Placed`);

      await n.wait();
      toast.success("Transaction Successful!");
      setUpdate((u) => u + 1);
      return n;
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.claimReflections = async (tokenIds) => {
    try {
      toast(`Placing Transaction`);

      const n = await contractObjects.stakingContract.claimReflections(
        tokenIds
      );
      toast(`Transaction Placed`);

      await n.wait();
      toast.success("Transaction Successful!");
      setUpdate((u) => u + 1);

      return n;
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.retrieveAll = async (signatures) => {
    try {
      const dataToSend = signatures.map(
        ({ signature, voucher: { tokenId, user } }) => {
          return [tokenId, user, signature];
        }
      );
      toast.info("Retrieving Tokens");
      console.log(dataToSend);
      const txn = await contractObjects?.chain2MigrateContract?.claimToken(
        dataToSend
      );
      toast.info("Transaction Placed");
      const res = await txn.wait();
      toast.success("Tokens Retrieved!");
    } catch (e) {
      console.log(e);
    }
  };

  functionsToExport.getChain2Nfts = async () => {
    try {
      const userBalance = parseInt(
        (
          await contractObjects?.chain2NftContract?.balanceOf(account)
        ).toString()
      );
      // const userBalance = 2;
      const [multicallProvider, multicallContract] =
        await setupMultiCallContract(
          contractAddresses?.CHAIN2_NFT_CONTRACT_ADDRESS,
          nftContract
        );
      let tokenCalls = [];
      for (let i = 0; i < userBalance; i++) {
        tokenCalls.push(multicallContract.tokenOfOwnerByIndex(account, i));
      }
      let [userTokens, signatures] = await Promise.all([
        (await multicallProvider?.all(tokenCalls)).map((e) => e.toString()),
        axios.get(
          `https://ezmigrate-backend.onrender.com/migrate/${selectedProject}/${account}`
        ),
      ]);

      const ownerTokenCalls = [];
      signatures = signatures?.data?.signatures?.filter(
        ({ voucher: { tokenId, user } }) => {
          ownerTokenCalls.push(multicallContract.ownerOf(tokenId));
          console.log(tokenId);
          for (let i in userTokens) {
            let token = userTokens[i];
            console.log(token.toString());
            if (token.toString() == tokenId?.toString()) {
              return false;
            }
          }
          return true;
        }
      );
      const existingTokens = (await multicallProvider?.all(tokenCalls)).map(
        (e) => e.toString()
      );

      console.log(signatures);
      // const userTokens = ["30", "207"];

      return [userTokens, signatures];
    } catch (e) {
      console.log(e);
      // toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.getContractBalance = async () => {
    return 0;
    const contractBalance = utils.formatEther(
      await contractObjects?.dustContract?.balanceOf(
        contractAddresses?.STAKING_CONTRACT_ADDRESS
      )
    );
    console.log(contractBalance);
    return contractBalance;
  };
  functionsToExport.getUnMigratedTokens = async () => {
    try {
      const userBalance = parseInt(
        (
          await contractObjects?.chain1NftContract?.balanceOf(account)
        ).toString()
      );
      // const userBalance = 2;
      const [multicallProvider, multicallContract] =
        await setupMultiCallContract(
          contractAddresses?.CHAIN1_NFT_CONTRACT_ADDRESS,
          nftContract
        );
      let tokenCalls = [];
      for (let i = 0; i < userBalance; i++) {
        tokenCalls.push(multicallContract.tokenOfOwnerByIndex(account, i));
      }
      let userTokens = (await multicallProvider?.all(tokenCalls)).map((e) =>
        e.toString()
      );
      console.log(userTokens, "usertokenss");
      // const userTokens = ["30", "207"];

      return userTokens;
    } catch (e) {
      console.log(e, "randomError");
      // toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.getUserUnStakedCount = async () => {
    try {
      const n = parseInt(
        (await contractObjects?.chibiContract?.balanceOf(account)).toString()
      );
      return n;
    } catch (e) {
      console.log(e);
      // toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.getUserStakedCount = async () => {
    try {
      const n = await contractObjects.stakingContract.getUserStaked(account);
      return n.length;
    } catch (e) {
      console.log(e);
      // toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.getRewards = async (tokenIds) => {
    try {
      const [multicallProvider, multicallContract] =
        await setupMultiCallContract(
          contractAddresses?.STAKING_CONTRACT_ADDRESS,
          chibiStaking
        );
      let tokenCalls = [];
      for (let i = 0; i < tokenIds.length; i++) {
        tokenCalls.push(multicallContract.getRewards(tokenIds[i]));
      }
      const rewards = (await multicallProvider?.all(tokenCalls)).map((e) =>
        e.toString()
      );
      return ethers.utils.formatEther(rewards[0]);
    } catch (e) {
      console.log(e);
      // toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.calculateReward = async (tokenIds) => {
    try {
      const [multicallProvider, multicallContract] =
        await setupMultiCallContract(
          contractAddresses?.NFT_CONTRACT_ADDRESS,
          nftContract
        );
      let tokenCalls = [];
      for (let i = 0; i < tokenIds.length; i++) {
        tokenCalls.push(multicallContract.calculateReward(tokenIds[i]));
      }
      const rewards = (await multicallProvider?.all(tokenCalls)).map((e) =>
        e.toString()
      );
      let rewardTokens = [];
      for (let i = 0; i < tokenIds.length; i++) {
        if (rewards[i]?.toString() !== "0") {
          rewardTokens.push(Number(tokenIds[i].toString()));
        }
      }
      return [rewards, rewardTokens];
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };

  functionsToExport.stakeCrusader = async (tokenIds) => {
    try {
      const isPlotsApproved =
        await contractObjects?.chibiContract?.isApprovedForAll(
          account,
          contractAddresses?.STAKING_CONTRACT_ADDRESS
        );
      if (!isPlotsApproved) {
        toast(`Approving Staking Contract`);

        const approveIt =
          await contractObjects?.chibiContract?.setApprovalForAll(
            contractAddresses?.STAKING_CONTRACT_ADDRESS,
            true
          );
        const newBattleId = await approveIt.wait();
        toast(`Staking Contract Approved!`);
      }

      toast.success(`Placing Transaction`);

      const n = await contractObjects.stakingContract.stake(tokenIds);
      await n.wait();
      setUpdate((u) => u + 1);
      toast.success("Transaction Successful");
      return n;
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.unstakeCrusader = async (tokenIds) => {
    try {
      const signatures = await axios.post(
        "https://crusader-staking-backend.onrender.com/all",
        {
          tokenIds: tokenIds.map((e) => parseInt(e.toString())),
        }
      );
      //   return;

      toast(`Placing Transaction`);
      console.log(signatures?.data?.data);
      const n = await contractObjects.stakingContractCrusader.unStake(
        signatures?.data?.data,
        { gasLimit: 10000000 }
      );
      toast(`Transaction Placed`);

      await n.wait();
      setUpdate((u) => u + 1);
      toast.success("Transaction Successful!");

      return n;
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.claimRewardsCrusader = async (tokenIds) => {
    try {
      const signatures = await axios.post(
        "https://crusader-staking-backend.onrender.com/all",
        {
          tokenIds: tokenIds.map((e) => parseInt(e.toString())),
        }
      );
      toast(`Placing Transaction`);

      const n = await contractObjects.stakingContractCrusader.claimRewards(
        signatures?.data?.data,
        { gasLimit: 10000000 }
      );
      setUpdate((u) => u + 1);
      toast(`Transaction Placed`);

      await n.wait();
      toast.success("Transaction Successful!");
      return n;
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.getUserStakedCrusader = async () => {
    try {
      const n = await contractObjects.stakingContract.getUserStaked(account);
      return n;
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.getUserUnStakedCrusader = async () => {
    try {
      const userBalance = parseInt(
        (await contractObjects?.chibiContract?.balanceOf(account)).toString()
      );
      // const userBalance = 2;
      nftContract.map((e) => console.log(e));
      const [multicallProvider, multicallContract] =
        await setupMultiCallContract(
          contractAddresses?.NFT_CONTRACT_ADDRESS,
          nftContract
        );
      let tokenCalls = [];
      for (let i = 0; i < userBalance; i++) {
        tokenCalls.push(multicallContract.tokenOfOwnerByIndex(account, i));
      }
      const userTokens = (await multicallProvider?.all(tokenCalls)).map((e) =>
        e.toString()
      );

      // const userTokens = ["30", "207"];
      return userTokens;
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.getUserUnStakedCountCrusader = async () => {
    try {
      const n = parseInt(
        (await contractObjects?.chibiContract?.balanceOf(account)).toString()
      );
      return n;
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.getUserStakedCountCrusader = async () => {
    try {
      const n = await contractObjects.stakingContract.getUserStaked(account);
      return n.length;
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };
  functionsToExport.getRewardsCrusader = async (tokenIds) => {
    try {
      const [multicallProvider, multicallContract] =
        await setupMultiCallContract(
          contractAddresses?.STAKING_CONTRACT_ADDRESS,
          crusaderStakingAbi
        );
      let tokenCalls = [];
      const signatures = (
        await axios.post("https://crusader-staking-backend.onrender.com/all", {
          tokenIds: tokenIds.map((e) => parseInt(e.toString())),
        })
      )?.data?.data;
      for (let i = 0; i < tokenIds.length; i++) {
        tokenCalls.push(
          multicallContract.getRewards(
            signatures[i][0],
            signatures[i][1],
            signatures[i][2],
            signatures[i][3]
          )
        );
      }
      const rewards = (await multicallProvider?.all(tokenCalls)).map((e) =>
        e.toString()
      );
      return ethers.utils.formatEther(rewards[0]);
    } catch (e) {
      console.log(e);
      toast.error(e?.message);
      return false;
    }
  };

  functionsToExport.getChain1Tokens = async () => {
    const userBalance = await contractObjects?.chain1NftContract?.balanceOf(
      account
    );
    console.log(userBalance);
    return userBalance;
  };

  functionsToExport.getChain2Tokens = async () => {
    try {
      const userBalance = await contractObjects?.chain2NftContract?.balanceOf(
        account
      );

      // const userBalance = 2;
      const [multicallProvider, multicallContract] =
        await setupMultiCallContract(
          contractAddresses?.CHAIN2_MIGRATE_CONTRACT_ADDRESS,
          migrateChain2TokenAbi
        );
      let tokenCalls = [];

      let [
        // userTokens,
        signatures,
      ] = await Promise.all([
        // (() => [])(),
        axios.get(
          `https://ezmigrate-backend.onrender.com/migrateToken/${selectedProject}/${account}`
        ),
      ]);

      const length = signatures?.data?.signatures?.length || 0;
      for (let i = 0; i < length; i++) {
        tokenCalls.push(multicallContract.userCollected(account, i));
      }
      const userTokens = await multicallProvider?.all(tokenCalls);
      signatures = signatures?.data?.signatures?.filter(
        ({ voucher: { id, amount, user } }, index) => {
          console.log(!userTokens[index]);
          return !userTokens[index];
        }
      );
      console.log(signatures);
      // const userTokens = ["30", "207"];

      return [userBalance, signatures];
    } catch (e) {
      console.log(e);
      // toast.error(e?.message);
      return false;
    }
  };

  functionsToExport.migrateErc20Tokens = async (amount) => {
    try {
      const availableBalance =
        await contractObjects?.chain1NftContract.allowance(
          account,
          contractAddresses?.CHAIN1_MIGRATE_CONTRACT_ADDRESS
        );
      console.log(availableBalance.toString());
      if (availableBalance.lt(amount)) {
        toast(`Increasing Allowance for Token (Placing Transaction)`);

        const increaseBal =
          await contractObjects?.chain1NftContract.increaseAllowance(
            contractAddresses?.CHAIN1_MIGRATE_CONTRACT_ADDRESS,
            amount
          );
        const result = await increaseBal.wait();
        toast.success("Allowance Increased!");
      }
      toast.info("Placing Transaction");
      const txn = await contractObjects?.chain1MigrateContract.sendToken(
        amount
      );
      toast.info("Transaction Placed!");
      const txnH = await txn.wait();
      toast.success("Transaction Successful");
    } catch (e) {
      console.log(e);
      toast.error("Transaction Failed!");
    }
  };
  functionsToExport.chibiCatsMigrate = async (signatures) => {
    try {
      if (signatures.length <= 150) {
        console.log(signatures);
        toast.info("Migrating Tokens");

        const txn = await contractObjects?.chibiMigrateContract?.migrate(
          signatures
        );
        toast.info("Transaction Placed");
        const res = await txn.wait();
        toast.success("Tokens Retrieved!");
      } else {
        const chunkSize = 150;
        toast.info("Migrating Tokens in Batches");

        for (let i = 0; i < signatures.length; i += chunkSize) {
          const chunk = signatures.slice(i, i + chunkSize);
          toast.info(`Migrating ${chunk.length} Tokens`);

          const txn = await contractObjects?.chibiMigrateContract?.migrate(
            chunk
          );
          toast.info("Transaction Placed");
          const res = await txn.wait();
          toast.success("Tokens Retrieved!");
        }
      }
    } catch (e) {
      console.log(e);
      toast.error("Transaction Failed");
    }
    setUpdate((u) => u + 1);
  };
  functionsToExport.retrieveAllTokens = async (signatures) => {
    const dataToSend = signatures.map(
      ({ signature, voucher: { id, amount, user } }) => {
        return [id, amount, user, signature];
      }
    );
    toast.info("Retrieving Tokens");
    console.log(dataToSend);
    const txn = await contractObjects?.chain2MigrateContract?.claimToken(
      dataToSend
    );
    toast.info("Transaction Placed");
    const res = await txn.wait();
    toast.success("Tokens Retrieved!");
  };

  return (
    <Web3Context.Provider
      value={{
        account,
        isPaused,
        baseCoinPrice,
        selectedProject,
        setSelectedProject,
        contractObjects,
        stats,
        update,
        currentNetwork,
        setCurrentNetwork,
        presentNetwork,
        promptChain,
        isToken,
        setIsToken,
        ...functionsToExport,
      }}
    >
      {props.children}
    </Web3Context.Provider>
  );
};
export default Web3Context;
