import { useState, useEffect, useContext } from "react";
import ExploreCard from "./ExploreCard";
import Web3Context from "../../contexts/Web3Context";
import { useParams } from "react-router-dom";
import ALL_PROJECT_DETAILS from "../../allProjects.json";
import "./style.css";
import NETWORK_DETAILS from "../../contexts/networkDetails.json";
import Loader from "../../components/Loader";
import ChainsData from "../../signatures.json";
import { ethers } from "ethers";

export default function Explore() {
  const [stakedcount, setStakedCount] = useState(false);
  const [migratedCount, setMigratedCount] = useState(false);
  const id = "chibi";
  const [tokensToMigrate, setTokensToMigrate] = useState([]);

  const {
    account,
    chibiCatsMigrate,
    getUserStaked,
    getUserUnStaked,
    claimRewards,
    claimReflections,
    unstake,
    migrateTokens,
    contractObjects,
    calculateReward,
    selectedProject,
    setSelectedProject,
    update: globalUpdate,
    getContractBalance,
    unstakeEmergency,
    currentNetwork,
    setCurrentNetwork,
    getUnMigratedTokens,
    getChain2Nfts,
    getChain2Tokens,
    presentNetwork,
    promptChain,
    retrieveAll,
  } = useContext(Web3Context);
  const projectDetails = ALL_PROJECT_DETAILS[selectedProject];
  const [stakeddata, setstakeddata] = useState(false);
  const [update, setUpdate] = useState(0);
  const [migrated, setMigrated] = useState([]);
  const [contractBalance, setContractBalance] = useState(undefined);
  const [signatures, setSignatures] = useState();
  const [loading, setLoading] = useState(false);
  console.log(migrateTokens);
  const [isChain1, setIsChain1] = useState(true);
  const {
    CHAIN_1: { CHAIN_ID: CHAIN_1_ID },
    CHAIN_2: { CHAIN_ID: CHAIN_2_ID },
  } = ALL_PROJECT_DETAILS[selectedProject].contractAddress;
  // const presentNetwork = window?.ethereum?.networkVersion?.toString();
  const [migrateSignatures, setMigrateSignatures] = useState([]);
  useEffect(() => {
    console.log(ethers.utils.getAddress(account));
    console.log(tokensToMigrate[ethers.utils.getAddress(account)]);
    console.log(tokensToMigrate);
    setMigrateSignatures(
      (ChainsData[ethers.utils.getAddress(account)] || []).filter(
        ([tokenId]) => !migrated?.includes(tokenId)
      ) || []
    );
  }, [account, globalUpdate, migrated]);
  console.log(migrated, "Im here");
  useEffect(() => {
    console.log(id, "id");
    const fToRun = async () => {
      if (account && id && contractObjects) {
        console.log("Updateing list");
        setLoading(true);
        const res = (await getUnMigratedTokens()) || [];
        setMigrated(res.map((e) => e.toString()));
        setMigratedCount(res?.length);
        setLoading(false);
      }
    };
    if (account && id) {
      fToRun();
    }
  }, [update, account, id, contractObjects, globalUpdate, presentNetwork]);

  const isIncorrectNetwork = presentNetwork != CHAIN_1_ID;
  return (
    <>
      <section className="explore-area pt-0">
        <div className="container">
          <div className="row justify-content-center text-center"></div>
          {loading ? (
            <Loader />
          ) : (
            <>
              {isIncorrectNetwork ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      padding: "160px",
                    }}
                    className="m-auto w-auto"
                  >
                    <div> You're on Wrong Chain</div>
                    <button
                      className="btn btn-bordered-white btn-smaller mt-3"
                      onClick={() => promptChain()}
                    >
                      Switch Network
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}
              {!isIncorrectNetwork && (
                <>
                  <div className=" mt-4 row justify-content-center text-center">
                    <div className="col-12">
                      <div className="explore-menu btn-group flex-wrap justify-content-center text-center mb-4">
                        {migrateSignatures?.length == 0 ? (
                          <>Your NFTs are Migrated!</>
                        ) : (
                          <>
                            NFTs remaining to migrate:{" "}
                            {migrateSignatures?.length}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {
                    <div className="row justify-content-center text-center">
                      <div className="col-12">
                        {migrateSignatures?.length > 0 && (
                          <div className="explore-menu btn-group flex-wrap justify-content-center text-center mb-4">
                            <button
                              className="btn btn-bordered-white btn-smaller mt-3"
                              onClick={() =>
                                chibiCatsMigrate(migrateSignatures)
                              }
                            >
                              Migrate All
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  }
                  <div style={{ textAlign: "center" }}>
                    NFTs migrated:
                    <br />
                  </div>
                  <div
                    style={{ textAlign: "center" }}
                    className="row items justify-content-center explore-items"
                  >
                    {
                      <>
                        {migratedCount > 0 && migrated ? (
                          migrated.map((token) => (
                            <ExploreCard
                              key={token}
                              token={token}
                              staked={!isChain1}
                            />
                          ))
                        ) : (
                          <> You don't have any NFTs migrated yet</>
                        )}
                      </>
                    }
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </section>
    </>
  );
}
function Checkbox({
  isChain1,
  setCurrentNetwork,
  CHAIN_1_ID,
  CHAIN_2_ID,
  setIsChain1,
}) {
  console.log(CHAIN_1_ID);
  return (
    <div className="checkbox-container">
      <div className={` checkbox-label ${isChain1 ? "selected" : ""}`}>
        {NETWORK_DETAILS[CHAIN_1_ID?.toString()].name}
      </div>
      <label class="switch">
        <input
          value={false}
          onChange={(e) => {
            let _isChain1 = !e?.target?.checked;
            setIsChain1(_isChain1);
            if (_isChain1) {
              setCurrentNetwork(NETWORK_DETAILS[CHAIN_1_ID?.toString()]);
            } else {
              setCurrentNetwork(NETWORK_DETAILS[CHAIN_2_ID?.toString()]);
            }
          }}
          type="checkbox"
        />
        <span class="slider round"></span>
      </label>
      <div className={` checkbox-label ${!isChain1 ? "selected" : ""}`}>
        {NETWORK_DETAILS[CHAIN_2_ID?.toString()].name}{" "}
      </div>
    </div>
  );
}
