import React, { useContext } from "react";
import Web3Context from "../../contexts/Web3Context";
import { useMetaMask } from "metamask-react";


const Header = () => {
    const { connectWallet, account } = useContext(Web3Context);

    return (
      <header id="header">
        {/* Navbar */}
        <nav
          data-aos="zoom-out"
          data-aos-delay={800}
          className="navbar navbar-expand"
          style={{ height: "100px" }}
        >
          <div className="container header">
            {/* Navbar Brand*/}
            <a className="navbar-brand" href="/">
              <img
                style={{ width: "100px", height: "100px" }}
                className=""
                src="/img/logo.png"
                alt="sticky brand-logo"
              />
            </a>
            <div className="ml-auto" />
            {/* Navbar */}
            <ul className="navbar-nav items mx-auto">
              <li className="nav-item dropdown">
                <a className="nav-link" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link" href="/explore">
                  Explore <i className="fas fa-angle-down ml-1" />
                </a>
                <ul className="dropdown-menu">
                  <li className="nav-item">
                    <a href="/explore" className="nav-link">
                      Explore Projects
                    </a>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <a className="nav-link" href="">
                  Community <i className="fas fa-angle-down ml-1" />
                </a>
                <ul className="dropdown-menu">
                  <li className="nav-item">
                    <a
                      target={"_blank"}
                      href="https://discord.gg/rPkYAvMhgW"
                      className="nav-link"
                    >
                      Discord
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="mailto:teamvikingstech@gmail.com">
                  Contact Us
                </a>
              </li>
            </ul>
            {/* Navbar Icons */}

            {/* Navbar Toggler */}
            <ul className="navbar-nav toggle">
              <li className="nav-item">
                <button class="navbar-toggler" type="button">
                  <i className="fas fa-bars toggle-icon m-0" />
                </button>
              </li>
            </ul>
            {/* Navbar Action Button */}
            {!account ? (
              <ul className="navbar-nav action">
                <li className="nav-item ml-3">
                  <a
                    onClick={connectWallet}
                    className="btn ml-lg-auto btn-bordered-white"
                  >
                    <i className="icon-wallet mr-md-2" />
                    Wallet Connect
                  </a>
                </li>
              </ul>
            ) : (
              <ul className="navbar-nav action">
                <li className="nav-item ml-3">
                  <a
                    // href="/wallet-connect"
                    className="btn ml-lg-auto btn-bordered-white"
                  >
                    <i className="icon-wallet mr-md-2" />
                    {`${account?.slice(0, 6)}...${account?.slice(-4)}`}
                  </a>
                </li>
              </ul>
            )}
          </div>
        </nav>
      </header>
    );
};

export default Header;
