import { useState, useEffect, useContext } from "react";
import ExploreCard from "./ExploreCard";
import Web3Context from "../../contexts/Web3Context";
import { useParams } from "react-router-dom";
import ALL_PROJECT_DETAILS from "../../tokenMigrateProject.json";
import "./style.css";
import NETWORK_DETAILS from "../../contexts/networkDetails.json";
import Loader from "../../components/Loader";
import { BigNumber, utils } from "ethers";
import Input from "./Input";
export default function Explore() {
  const [stakedcount, setStakedCount] = useState(false);
  const [unMigratedCount, setUnMigratedCount] = useState(false);
  const { id } = useParams();

  const {
    account,
    getUserStaked,
    getUserUnStaked,
    claimRewards,
    claimReflections,
    unstake,
    migrateTokens,
    contractObjects,
    calculateReward,
    selectedProject,
    setSelectedProject,
    update: globalUpdate,
    getContractBalance,
    unstakeEmergency,
    currentNetwork,
    setCurrentNetwork,
    getUnMigratedTokens,
    getChain2Tokens,
    presentNetwork,
    promptChain,
    retrieveAll,
    getChain1Tokens,
    migrateErc20Tokens,
    retrieveAllTokens,
  } = useContext(Web3Context);
  const projectDetails = ALL_PROJECT_DETAILS[selectedProject];
  const [stakeddata, setstakeddata] = useState(0);
  const [update, setUpdate] = useState(0);
  const [unMigrated, setUnMigrated] = useState(false);
  const [contractBalance, setContractBalance] = useState(undefined);
  const [signatures, setSignatures] = useState();
  const [loading, setLoading] = useState(false);

  async function claimRewardsclicked(e) {
    e.preventDefault();
    await claimRewards(stakeddata);
    setUpdate((val) => val + 1);
  }
  async function unstakeclicked(e) {
    e.preventDefault();
    // await retrieveAll(signatures);
    await retrieveAllTokens(signatures);

    setUpdate((val) => val + 1);
  }
  async function stakeclicked(e) {
    e.preventDefault();
    await migrateErc20Tokens(unMigratedCount);
    setUpdate((val) => val + 1);
  }
  async function claimReflectionsclicked(e) {
    e.preventDefault();
    const tokens = await calculateReward(stakeddata);
    await claimReflections(tokens[1]);
    setUpdate((val) => val + 1);
  }
  const [isChain1, setIsChain1] = useState(true);
  const {
    CHAIN_1: { CHAIN_ID: CHAIN_1_ID },
    CHAIN_2: { CHAIN_ID: CHAIN_2_ID },
  } = ALL_PROJECT_DETAILS[selectedProject].contractAddress;
  // const presentNetwork = window?.ethereum?.networkVersion?.toString();
  const [chain2Balance, setChain2Balance] = useState(0);
  useEffect(() => {
    const fToRun = async () => {
      if (id) {
        setSelectedProject(id);
      }
      if (account && id && contractObjects) {
        console.log("Updateing list");
        setLoading(true);
        if (isChain1 && presentNetwork == CHAIN_1_ID) {
          const res = await getChain1Tokens();
          setUnMigrated(res);
          setUnMigratedCount(res);
        } else if (!isChain1 && presentNetwork == CHAIN_2_ID) {
          const [userTokens, _signatures] = await getChain2Tokens();
          console.log(_signatures);
          console.log(userTokens);
          // setChain2Balance(userTokens.toString());
          setstakeddata(userTokens);
          setSignatures(_signatures);
          setStakedCount(_signatures?.length);
          let totalSum = BigNumber.from(0);
          _signatures?.map((e) => {
            let amount = e?.voucher?.amount;
            console.log(amount);
            totalSum = totalSum.add(BigNumber.from(amount));
          });
          setChain2Balance(totalSum);
        } else {
          console.log("wrong?");
        }
        setLoading(false);
      }
    };
    if (account && id) {
      fToRun();
    }
    console.log("Run?", account, id, contractObjects);
  }, [update, account, id, contractObjects, globalUpdate, presentNetwork]);

  const isIncorrectNetwork =
    (isChain1 && presentNetwork != CHAIN_1_ID) ||
    (!isChain1 && presentNetwork != CHAIN_2_ID);

  return (
    <>
      <section className="explore-area pt-0">
        <div className="container">
          <div className="row justify-content-center text-center">
            <Checkbox
              isChain1={isChain1}
              setCurrentNetwork={setCurrentNetwork}
              CHAIN_1_ID={CHAIN_1_ID}
              CHAIN_2_ID={CHAIN_2_ID}
              setIsChain1={setIsChain1}
            />
          </div>
          {loading ? (
            <Loader />
          ) : (
            <>
              {isIncorrectNetwork ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      padding: "160px",
                    }}
                    className="m-auto w-auto"
                  >
                    <div> You're on Wrong Chain</div>
                    <button
                      className="btn btn-bordered-white btn-smaller mt-3"
                      onClick={() => promptChain()}
                    >
                      Switch Network
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}

              {!isIncorrectNetwork && (
                <>
                  <div className=" mt-4 row justify-content-center text-center">
                    <div className="col-12">
                      <div className="explore-menu btn-group flex-wrap justify-content-center text-center mb-4">
                        {isChain1 ? (
                          unMigratedCount ? (
                            <>
                              You have{" "}
                              {utils.formatEther(unMigratedCount.toString())}{" "}
                              {utils.formatEther(unMigratedCount.toString()) ==
                              1
                                ? "xGSM"
                                : "xGSM"}{" "}
                              to Migrate
                            </>
                          ) : (
                            "You don't have any Tokens to Migrate"
                          )
                        ) : stakedcount ? (
                          <>
                            You have {utils.formatEther(chain2Balance)}{" "}
                            {stakedcount.toString() == 1 ? "xGSM" : "xGSM"} to
                            Retrieve
                            {/* ({stakedcount} Transaction
                            {stakedcount == 1 ? "" : "s"}) */}
                          </>
                        ) : (
                          "You don't have any Tokens to Retrieve!"
                        )}
                      </div>

                      <div className="col ">
                        <div
                          style={{ visibility: "hidden" }}
                          className="explore-menu btn-group flex-wrap justify-content-center text-center m-4"
                        ></div>{" "}
                      </div>
                    </div>
                  </div>
                  {isChain1 ? (
                    <div className="row justify-content-center text-center">
                      <div className="col-2">
                        {!projectDetails?.disableStaking &&
                          unMigrated &&
                          utils.formatEther(unMigrated.toString()) != 0 && (
                            <div className="explore-menu btn-group flex-wrap justify-content-center text-center ">
                              <button
                                className="btn btn-bordered-white btn-smaller mt-3"
                                onClick={stakeclicked}
                              >
                                Migrate
                              </button>
                            </div>
                          )}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="row justify-content-center text-center">
                        <div className="col-12">
                          {stakedcount > 0 && (
                            <div className="explore-menu btn-group flex-wrap justify-content-center text-center mb-4">
                              <button
                                className="btn btn-bordered-white btn-smaller mt-3"
                                onClick={unstakeclicked}
                              >
                                Retrieve All
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row justify-content-center text-center">
                        Your current xGSM Balance:{" "}
                        {utils.formatEther(stakeddata)} xGSM
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </section>
    </>
  );
}
function Checkbox({
  isChain1,
  setCurrentNetwork,
  CHAIN_1_ID,
  CHAIN_2_ID,
  setIsChain1,
}) {
  return (
    <div className="checkbox-container">
      <div className={` checkbox-label ${isChain1 ? "selected" : ""}`}>
        {NETWORK_DETAILS[CHAIN_1_ID?.toString()].name}
      </div>
      <label class="switch">
        <input
          value={false}
          onChange={(e) => {
            let _isChain1 = !e?.target?.checked;
            setIsChain1(_isChain1);
            if (_isChain1) {
              setCurrentNetwork(NETWORK_DETAILS[CHAIN_1_ID?.toString()]);
            } else {
              setCurrentNetwork(NETWORK_DETAILS[CHAIN_2_ID?.toString()]);
            }
          }}
          type="checkbox"
        />
        <span class="slider round"></span>
      </label>
      <div className={` checkbox-label ${!isChain1 ? "selected" : ""}`}>
        {NETWORK_DETAILS[CHAIN_2_ID?.toString()].name}{" "}
      </div>
    </div>
  );
}
