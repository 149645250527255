import { get } from "axios";
import { useEffect, useState, useContext } from "react";
import Web3Context from "../../contexts/Web3Context";
import { ethers } from "ethers";
import ALL_PROJECT_DETAILS from "../../allProjects.json";
export default function ExploreCard({ token, staked }) {
  const {
    claimRewards,
    claimReflections,
    migrateTokens,
    unstake,
    getRewards,
    calculateReward,
    selectedProject,
    update,
    unstakeEmergency,
  } = useContext(Web3Context);
  const projectDetails = ALL_PROJECT_DETAILS[selectedProject];
  async function stakeclick(e) {
    e.preventDefault();
    await migrateTokens([token]);
  }
  async function unstakeclick(e) {
    e.preventDefault();
    projectDetails?.disableStaking
      ? await unstakeEmergency([token])
      : await unstake([token]);
  }
  async function claimReflectionsclick(e) {
    e.preventDefault();
    await claimReflections([token]);
  }
  async function claimRewardsclick(e) {
    e.preventDefault();
    await claimRewards([token]);
  }

  const [name, setName] = useState(false);
  const [reward, setReward] = useState(false);
  const [reflectionreward, setReflection] = useState(false);
  async function getdetails() {
    try {
      const { data } = await get(`${projectDetails?.dataUri}${token}`);
      setName(data.name);
    } catch (e) {
      setName(`#${token}`);
    }
  }
  useEffect(() => {
    getdetails();
  }, [update]);

  return (
    <div className="col-12 col-sm-6 col-lg-4 item explore-item">
      <div className="card">
        <div className="image-over">
          <img
            className="card-img-top"
            src={`${projectDetails?.imageUri}${token}${projectDetails?.imageExtension}`}
            alt=""
          />
        </div>
        {/* Card Caption */}
        <div className="card-caption col-12 p-0">
          {/* Card Body */}
          <div className="card-body">
            <h5 className="mb-2">{name}</h5>
            {/* <div className="seller d-flex align-items-center my-3">
                        <span>Owned By</span>
                        <a href="/author">
                            <h6 className="ml-2 mb-0">{owner}</h6>
                        </a>
                    </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
