import { Web3Provider } from "./contexts/Web3Context";
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// importing MyRouts where we located all of our theme
import MyRouts from "./routers/routes";
import ProjectsDetails from "./pages/ProjectDetails/index";
import Projects from "./pages/Projects";

import Home from "./pages/Home";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TokenDetailPage from "./pages/TokenDetailPage";
import ChibiCatsPage from "./pages/ChibiCatsPage";

function App() {
  return (
    <div>
      <Web3Provider>
        <Header />

        <div style={{ minHeight: "99vh" }}>
          <ToastContainer />

          <Router>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/explore" component={Projects} />
              <Route exact path="/nfts/chibi" component={ChibiCatsPage} />
              <Route exact path="/nfts/:id" component={ProjectsDetails} />
              <Route exact path="/tokens/:id" component={TokenDetailPage} />
            </Switch>
          </Router>
        </div>
        <Footer />

        {/* <MyRouts /> */}
      </Web3Provider>
    </div>
  );
}

export default App;
